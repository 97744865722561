import React  from 'react';
import logo from '../../data/appData/appImages/entete/logo1.png'
import './appHeaderCss.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/fontawesome-free-solid';

function Header(){

    return(
        <div>
            <div id='bandeauId' className="bandeauClassResp">

                <div id='logoDiv' className="logoDivClassResp">

                    
                    <img id='logoImageId'   src={logo} alt='pizzeria royal fast food Reims' />

    
                </div>
                <div className="addTel">
                    <div className="addDiv">
                        <FontAwesomeIcon icon={faMapMarker} />  92 Rue Maison Blanche, Reims<br/>
                    </div>
                </div>
            </div>
            
           

        </div>

    );
}
export default Header