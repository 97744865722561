import React from "react";
import CommandLink from '../commandLink/CommandLink'
import MenuItem from '../menuItem/MenuItem'
import './appWelcomeCss.css' ;
import formuleLogo from '../../data/appData/appImages/carteCatalogue/250PX/formule.png'
import pizzaTLogo from '../../data/appData/appImages/carteCatalogue/250PX/pizza.png'
import pizzaCLogo from '../../data/appData/appImages/carteCatalogue/250PX/pizza-creme-fraiche.png'
import assietteLogo from '../../data/appData/appImages/carteCatalogue/250PX/assiette.png'
import tacosLogo from '../../data/appData/appImages/carteCatalogue/250PX/tacos.png'
import sandwichLogo from '../../data/appData/appImages/carteCatalogue/250PX/sandwich.png'
import texMexLogo from '../../data/appData/appImages/carteCatalogue/250PX/texMex.png'
import saladeLogo from '../../data/appData/appImages/carteCatalogue/250PX/salade.png'
import dessertLogo from '../../data/appData/appImages/carteCatalogue/250PX/dessert.png'
import boissonLogo from '../../data/appData/appImages/carteCatalogue/250PX/boisson.png'

function Content(){


    const menuItemTable =[
        [formuleLogo, "https://www.walima-eat.com/#/etablissements/51100/3/site/category1"],
        [pizzaTLogo, "https://www.walima-eat.com/#/etablissements/51100/3/site/category2"],
        [pizzaCLogo, "https://www.walima-eat.com/#/etablissements/51100/3/site/category3"],
        [assietteLogo, "https://www.walima-eat.com/#/etablissements/51100/3/site/category4"],
        [tacosLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category5'],
        [sandwichLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category6'],
        [texMexLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category7'],
        [saladeLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category8'],
        [dessertLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category9'],
        [boissonLogo, 'https://www.walima-eat.com/#/etablissements/51100/3/site/category10']
    ];
    return(

        <div className='content1Div'>
            <CommandLink></CommandLink>

            <div className="centePerCentWidthAndFloatContainer">
                <div className='rowContainer rowContainerResp'>
                    
                    <div>
                        {menuItemTable.map((items, index) => {
                                return (
                                    <MenuItem menuItemLogo={items[0]}  menuItemLink={items[1]}  key={index}></MenuItem>
                                );

                            })}
                    </div>
                
                </div>
            </div>
            

        </div>

    );
}

export default Content